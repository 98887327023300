<template>
  <div class="mobile-fix-page">
    <div class="mobile-fix-page__wrapper">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>维修服务及改造</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="content">
        <span>{{message}}</span>
      </div>
      <img src="@/assets/png/fix-tip.jpg" alt="" style="width: 100%;margin-top: 16px;">
    </div>
  </div>
</template>

<script>
  import axios from 'axios'
  export default {
    data() {
      return {
        message: ''
      }
    },
    mounted() {
      this.getCompanyInfo()
    },
    methods: {
      getCompanyInfo() {
        axios.get('/api/company').then(data => {
          this.message = data.data.fix
        })
      }
    }
  }
</script>

<style lang="less">
  .mobile-fix-page {
    width: 100%;
    padding: 8px;
    &__wrapper {
      .el-breadcrumb {
        margin-bottom: 16px;
        font-size: 18px;
        padding-bottom: 16px;
        border-bottom: 1px solid #cccccc;
      }
      .content {
      
      }
    }
  }
</style>
